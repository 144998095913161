<template>
	<div class="container">
		<!-- 旧版闪电入口 -->
		<el-alert
			v-if="isFirstLogin==1"
			@close="closeAlert"
			type="warning"
			center>
			<div slot="title" class="alert" @click="jumpOld">
				{{$t('head.oldWeb')}}
				<i class="iconfont2 icon-right_arrow"></i>
			</div>
		</el-alert>
		<!-- PC及适配 header -->
		<div v-if="isMobile==0"
		class="header" :class="{
			mobile: device === 'mobile',
			openSide:!isCollapse
		}">
			<div class="left" :class="{hide: isCollapse}">
				<div class="logo">
					<img src="@/assets/images/logo.png" />
					<!-- <img src="@/assets/images/official/logo.png" /> -->
					
				</div>
				<div class="menuBtn" @click="handleClickOutside"><i class="iconfont icon-menu"></i></div>
			</div>
			<div class="right">
				<div class="info" v-if="device!='mobile'">
					{{ $t('common.endtime') }}：{{available_time}}
					<!-- <router-link class="btn" to="/renew">{{ $t('common.renew') }}</router-link> -->
					<div class="btn" @click="tipTo">{{ $t('common.renew') }}</div>
				</div>
				<div class="set" v-if="isSmall!='small'">
					<!-- <el-popover
						class="item"
						placement="bottom"
						width="100"
						trigger="click"
					> -->
						<!--  slot="reference" -->
					<div class="item">
						<div class="icon" @click="themeChange">
							<i v-if="theme!='light'" class="iconfont icon-moon"></i>
							<i v-else class="iconfont2 icon-rijian"></i>
						</div>
					</div>
						<!-- <ul class="head_list">
							<li :class="{active: theme=='light'}" @click="themeChange('light')">{{ $t('head.dayMode') }}</li>
							<li :class="{active: theme!='light'}" @click="themeChange('dark')">{{ $t('head.nightMode') }}</li>
						</ul>
					</el-popover> -->
					<el-popover
						class="item"
						placement="bottom"
						width="100"
						trigger="click"
					>
						<div class="icon" slot="reference">
							<i class="iconfont icon-lang"></i>
						</div>
						<ul class="head_list">
							<li v-for="(item,key,index) in langList" :key="index" :class="{active: lang == key}" @click="langChange(key)">{{item}}</li>
						</ul>
					</el-popover>
					<el-popover
						class="item"
						placement="bottom"
						width="160"
						trigger="click"
					>
						<div class="icon" slot="reference">
							<i class="iconfont icon-user"></i>
							{{account}}
						</div>
						<ul class="head_list">
							<li :class="{active: activePathTest == '/renew'}">
								<!-- <router-link to="/renew" :class="{en: lang!='zh'}"><i class="iconfont2 icon-level2"></i><span>{{ $t('head.lv') }}</span></router-link> -->
								<div @click="tipTo" :class="{en: lang!='zh'}"><i class="iconfont2 icon-level2"></i><span>{{ $t('head.lv') }}</span></div>
							</li>
							<li :class="{active: activePathTest == '/user'}">
								<router-link to="/user" :class="{en: lang!='zh'}"><i class="iconfont2 icon-account2"></i><span>{{ $t('head.center') }}</span></router-link>
							</li>
							<li :class="{active: activePathTest == '/invite'}">
								<router-link to="/invite" :class="{en: lang!='zh'}"><i class="iconfont2 icon-invitation"></i><span>{{ $t('head.invite') }}</span></router-link>
							</li>
							<!-- 最新公告 -->
							<!-- <li :class="{active: showNotice}">
								<div @click="openNotice" :class="{en: lang!='zh'}"><i class="iconfont2 icon-gonggao"></i><span>{{ $t('head.notice') }}</span></div>
							</li> -->
							<!-- 联系我们 -->
							<li :class="{active: activePathTest == '/contact'}">
								<router-link to="/contact" :class="{en: lang!='zh'}"><i class="iconfont icon-contact"></i><span>{{ $t('aside.list[12]') }}</span></router-link>
							</li>
							<li class="last" @click="logOut">{{ $t('head.out') }}</li>
						</ul>
					</el-popover>
				</div>
			</div>
		</div>
		<!-- 移动端 header -->
		<div v-else-if="isMobile==1"
		class="phoneHeader">
			<!-- 返回上层按钮 -->
			<div 
			v-if="
				$route.path=='/stocksFull'||
				$route.path=='/record'||
				$route.path=='/addrobot'||
				$route.path=='/centerUser'||
				$route.path=='/centerSafe'||
				$route.path=='/centerLang'||
				$route.path=='/centerTheme'||
				$route.path=='/inviteRecord'||
				$route.path=='/recharge'||
				$route.path=='/renewRecord'||
				$route.path=='/withdrawal'||
				$route.path=='/withdrawalRecord'
			" class="menuBtn" @click="goBack"><i class="iconfont2 icon-left"></i></div>
			<!-- 菜单按钮 -->
			<div v-else class="menuBtn" @click="handleClickOutside"><i class="iconfont icon-menu"></i></div>

			<div>{{$t(activeTitle)}}</div>

			<div class="menuBtn">
				<!-- 语言切换 -->
				<el-popover v-if="$route.path=='/stocks'"
					class="item"
					placement="bottom"
					width="100"
					trigger="click"
				>
					<div class="icon" slot="reference">
						<i class="iconfont icon-lang"></i>
					</div>
					<ul class="head_list_phone">
						<li v-for="(item,key,index) in langList" :key="index" :class="{active: lang == key}" @click="langChange(key)">{{item}}</li>
					</ul>
				</el-popover>

				<!-- 新增弹窗按钮 -->
				<div class="g_btn add_btn" 
				v-else-if="
					$route.path=='/account'||
					$route.path=='/alarm'
				"
				@click="addBtn">
					<i class="iconfont2 icon-roundadd"></i>
				</div>

				<!-- 新增跳转按钮 -->
				<div class="g_btn add_btn" v-else-if="$route.path=='/robot'"
				@click="jumpBtn('/addrobot')">
					<i class="iconfont2 icon-roundadd"></i>
				</div>

				<!-- 跳转记录按钮-邀请 -->
				<div class="g_btn add_btn" v-else-if="$route.path=='/invite'"
				@click="jumpBtn('/inviteRecord')">
					<i class="iconfont2 icon-jilu1"></i>
				</div>
				<!-- 跳转记录按钮-充值 -->
				<div class="g_btn add_btn" v-else-if="$route.path=='/recharge'"
				@click="jumpBtn('/renewRecord')">
					<i class="iconfont2 icon-jilu1"></i>
				</div>
				<!-- 跳转记录按钮-提现 -->
				<div class="g_btn add_btn" v-else-if="$route.path=='/withdrawal'"
				@click="jumpBtn('/withdrawalRecord')">
					<i class="iconfont2 icon-jilu1"></i>
				</div>

				<!-- 筛选按钮 -->
				<div 
				class="g_btn add_btn" 
				v-else-if="
					$route.path=='/record'||
					$route.path=='/robotlog'
				"
				@click="filterBtn">
					<i class="iconfont2 icon-jilu1"></i>
				</div>
			</div>
		</div>

		<!-- PC及适配 侧边栏 -->
		<div v-if="isMobile==0" class="section" :class="classObj">
			<div class="aside" :class="{hide: isCollapse}">
				<div class="aside_menu">
					<el-menu
						class="el-menu-vertical-demo"
						:default-active="activePathTest"
						:collapse="isCollapse"
						:router="true"
						@select="jumpback"
					>
						<template v-for="(item,index) in asideList">
							<!-- sub标题 -->
							<el-menu-item index="7" disabled v-if="item.isDisable" :key="index">{{ $t('aside.list')[index] }}</el-menu-item>
							<!-- 新手教程-外部跳转，隐藏时展示内部跳转 -->
							<el-menu-item :index="null" v-else-if="item.index=='/course'" :key="index" @click="jumpSite" class="jump_site">
								<i :class="['iconfont',item.icon]"></i>
								<span>{{ $t('aside.list')[index] }}</span>
							</el-menu-item>
							<!-- 模块 novice新手引导样式  -->
							<el-menu-item :index="item.index" :key="index" v-else :class="{
								novice:($route.path=='/stocks'&&novice==1&&helpNum==2&&index==0)||($route.path=='/stocks'&&novice==1&&helpNum==3&&index==2)||($route.path=='/stocks'&&novice==1&&helpNum==4&&index==5),
								disableHelp:novice==1&&$route.path=='/stocks'
							}"
							:disabled="novice==1&&helpNum!=1&&$route.path=='/stocks'">
								<i :class="[item.icon=='icon-rizhi'?'iconfont2':'iconfont',item.icon]"></i>
								<span>{{ $t('aside.list')[index] }}</span>
							</el-menu-item>
						</template>
						<!-- 新手引导-小屏适配侧边栏遮罩 -->
						<div class="mask" v-if="novice==1&&$route.path=='/stocks'&&device==='mobile'&&!isCollapse"></div>
					</el-menu>
				</div>
				<div class="aside_bottom">
					<div class="g_btn logout" v-if="isSmall === 'small'" @click="logOut">{{$t('head.out')}}</div>
					<div class="version">{{$t('login.version')}}：{{version}}</div>
				</div>
			</div>
			<!-- 遮罩层 -->
			<div v-if="device==='mobile'&&!isCollapse" class="drawer-bg" @click="handleClickOutside"></div>
			
			<div class="main">
				<router-view></router-view>
			</div>
		</div>
		<!-- 移动端 侧边栏 -->
		<div v-else-if="isMobile==1" class="phoneSection" :class="{notTrans: notTrans,hasAlert:isFirstLogin==1}">
			<div class="aside" :class="{hide: isCollapse}">
				<div class="aside_menu">
					<div class="selectAside">{{$t(selectAside)}}</div>
					<el-menu
						class="el-menu-vertical-demo"
						:default-active="activePathTest"
						:collapse="isCollapse"
						:router="true"
						@select="jumpback"
					>
						<template v-for="(item,index) in asideList">
							<el-menu-item index="7" disabled v-if="item.isDisable" :key="index">{{ $t('aside.list')[index] }}</el-menu-item>
							
							<!-- 新手教程-外部跳转，隐藏时展示内部跳转 -->
							<el-menu-item :index="null" v-else-if="item.index=='/course'" :key="index" @click="jumpSite">
								<i :class="['iconfont',item.icon]"></i>
								<span>{{ $t('aside.list')[index] }}</span>
							</el-menu-item>

							<el-menu-item :index="item.index" :key="index" v-else>
								<i :class="[item.icon=='icon-rizhi'?'iconfont2':'iconfont',item.icon]"></i>
								<span>{{ $t('aside.list')[index] }}</span>
							</el-menu-item>
						</template>
						
					</el-menu>
				</div>
				<div class="aside_bottom">
					<div class="g_btn logout" v-if="isSmall === 'small'" @click="logOut">{{$t('head.out')}}</div>
					<div class="version">{{$t('login.version')}}：{{version}}</div>
				</div>
			</div>
			<!-- 遮罩层 -->
			<div v-if="!isCollapse" class="drawer-bg" @click="handleClickOutside"></div>
			<div v-if="isMobile==1" class="main">
				<router-view></router-view>
			</div>
		</div>
		
		<!-- 最新公告 -->
		<!-- <el-dialog class="w900" center :visible.sync="showNotice" :show-close="false">
			<div slot="title">
				<div class="welcomeHead">
					<div>{{$t('head.notice')}}</div>
					<div class="close" @click="showNotice=false">
						<i class="iconfont2 icon-close-circle"></i>
					</div>
				</div>
			</div>
			<div class="welcomeText" v-html="'公告'"></div>
			<div class="welcomeFoot g_btn" @click="showNotice=false">
				{{$t('stocks.know')}}
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResizeMixin from './homeResize'
import langList from '@/assets/js/langList'
import bus from "./bus.js";
import guidance from '@/components/guidance.vue';
// 新手教程pdf预加载
import bps_zh from "@/assets/pdfFile/toturial_zh.pdf"
import bps_en from "@/assets/pdfFile/toturial_en.pdf"
import bps_ko from "@/assets/pdfFile/toturial_ko.pdf"
	export default ({
		name: 'home',
  		mixins: [ResizeMixin],
		components: {
			guidance
		},
		data() {
			return {
				myRouteHistory:'',//我的跳转记录 用于返回上层

				langList:langList,

				// isCollapse: false,
				notTrans:false,

				showNotice:false,

				asideList:[
					{
						index:'/account',
						icon:'icon-account',
					},{
						index:'/stocks',
						icon:'icon-pan',
					},{
						index:'/robot',
						icon:'icon-robot',
					},
					{
						index:'/robotlog',
						icon:'icon-rizhi',
					},
					{
						index:'/data',
						icon:'icon-echart',
					},{
						index:'/property',
						icon:'icon-data',
					},{
						index:'/alarm',
						icon:'icon-alert',
					},{
						isDisable:true,
					},
					// {
					// 	index:'/renew',
					// 	icon:'icon-check',
					// },
					{
						index:'/user',
						icon:'icon-center',
					},{
						index:'/invite',
						icon:'icon-invite',
					},{
						isDisable:true,
					},{
						index:'/course',
						icon:'icon-course',
					},{
						index:'/contact',
						icon:'icon-contact',
					},
				],
				
			}
		},
		methods: {
			// 新手教程-外部跳转
			jumpSite(){
				var pdfFile=this.lang=='zh'? bps_zh:
				this.lang=='ko'? bps_ko:bps_en  
				window.open(pdfFile, "_blank")
			},
			// 关闭入口
			closeAlert(){
				this.$store.commit('user/SET_IS_FIRST_LOGIN', '0')
				localStorage.setItem('isFirstLogin', '0');
			},
			// 旧版闪电入口
			jumpOld(){
            	window.location.href='http://www.llkkz.net/lightbot/cn/login.html'
			},
			openNotice(){
				// 获取最新公告
				this.showNotice=true;
			},
			tipTo(){
				// if(this.account=='guanxiny1'){
					this.$router.push({
						path: '/renew',
					})
				// }else{
				// 	// 请您联系商务客服续费！
				// 	this.$message({
				// 		message: this.$t('tip.renewTip'),
				// 		type: 'info',
				// 		center: true,
				// 		customClass: 'shotMsg',
				// 	});
				// }
			},
			// 移动端触发返回上层
			goBack(){
				var path=this.$route.path
				// if (window.history.length <= 1) {
				// if (!this.myRouteHistory) {
					if(
						path=='/stocksFull'||
						path=='/record'
					){
						this.$router.push({ path: "/stocks" });
					}else if(path=='/addrobot'){
						this.$router.push({ path: "/robot" });
					}else if(path=='/inviteRecord'){
						this.$router.push({ path: "/invite" });
					}else if(
						path=='/centerUser'||
						path=='/centerSafe'||
						path=='/centerLang'||
						path=='/centerTheme'
					){
						this.$router.push({ path: "/user" });
					}else if(
						path=='/recharge'||
						path=='/withdrawal'
					){
						this.$router.push({ path: "/renew" });
					}else if(path=='/renewRecord'){
						this.$router.push({ path: "/recharge" });
					}else if(path=='/withdrawalRecord'){
						this.$router.push({ path: "/withdrawal" });
					}
					return false;
				// } else {
				// 	this.$router.push({
				// 		path:this.myRouteHistory
				// 	})
				// 	this.myRouteHistory='';
				// 	// this.$router.go(-1);
				// }
			},
			// 移动端触发add
			addBtn(){
				bus.$emit('add')
			},
			// 移动端触发跳转按钮
			jumpBtn(path){
				this.$router.push({
					path
				})
			},
			// 移动端触发筛选按钮
			filterBtn(){
				bus.$emit('filter')
			},

			// 侧边栏选中回调
			jumpback(){
				// 如果移动端 侧边栏点击后收起
				if(this.device === 'mobile'||this.isMobile==1){
					// this.isCollapse=true;
					this.$store.commit('app/SET_IS_COLLAPSE', true);
				}
			},
			// 侧边栏展/合
    		handleClickOutside() {
				// this.isCollapse = !this.isCollapse;
				this.$store.commit('app/SET_IS_COLLAPSE', !this.isCollapse);
				this.notTrans=false;
			},
			// 主题切换
			themeChange(){//theme
				var theme=this.theme=='dark'? 'light':'dark'
				this.$store.dispatch('app/steTheme',theme)
			},
			langChange(value) {
				this.$i18n.locale = value;
				// this.lang = value;
				// localStorage.setItem('lang', value);
				this.$store.dispatch('app/setLang',value)
			},
			logOut() {
				// this.$alert('退出登录');
				this.$confirm(this.$t('head.outTip'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.cancel'),
					center: true,
					customClass: 'deleteOk',
				}).then(() => {
					// 退出登录
					this.$store.dispatch('user/logout').then(res=>{
						this.$router.push({
						    path:'/login'
						})
					})
				}).catch(() => {
					// 取消
				});
			},
		},
		computed:{
			...mapGetters(['account','available_time','device','isSmall','lang','isMobile','theme','novice','helpNum','isCollapse','isFirstLogin','version','email']),
			activePathTest:function(){
				var path=this.$route.path
				if(path=='/addrobot'){//||path=='/robotlog'
					return '/robot'
				}else if(path=='/recharge'||path=='/withdrawal'){
					return '/renew'
				}else if(path=='/stocksFull'||path=='/subManage'||path=='/record'){
					return '/stocks'
				}else{
					return path
				}
			},
			// 侧边栏选中项
			selectAside:function(){
				// this.activePathTest
				var path=this.$route.path
				if(path=='/account'){
					return 'aside.list[0]'
				}else if(path=='/stocks'||path=='/subManage'||path=='/record'){
					return 'aside.list[1]'
				}else if(path=='/robot'||path=='/addrobot'){
					return 'aside.list[2]'
				}else if(path=='/robotlog'){
					return 'aside.list[3]'
				}else if(path=='/data'){
					return 'aside.list[4]'
				}else if(path=='/property'){
					return 'aside.list[5]'
				}else if(path=='/alarm'){
					return 'aside.list[6]'
				}else if(
					path=='/renew'||
					path=='/recharge'||
					path=='/renewRecord'||
					path=='/withdrawal'||
					path=='/withdrawalRecord'
				){
					return 'aside.renew'
				}else if(
					path=='/user'||
					path=='/centerUser'||
					path=='/centerSafe'||
					path=='/centerLang'||
					path=='/centerTheme'
				){
					return 'aside.list[8]'
				}else if(
					path=='/invite'||
					path=='/inviteRecord'
				){
					return 'aside.list[9]'
				}else if(path=='/course'){
					return 'aside.list[11]'
				}else if(path=='/contact'){
					return 'aside.list[12]'
				}
			},
			// 标题栏title
			activeTitle:function(){
				return this.$route.meta.title
			},
			classObj() {
				return {
        			notTrans: this.notTrans,
					mobile: this.device === 'mobile',
					openSide:!this.isCollapse,
					hasAlert:this.isFirstLogin==1
				}
			},
		},
		created(){
			this.$store.dispatch('user/getInfo').then(res=>{
				// 判断是否过期
				var available_time=new Date(res.available_time).getTime();
				var now=new Date().getTime();
				if(available_time<now){
					this.$confirm(this.$t('tip.expired'), this.$t('tip.tips'), {
						confirmButtonText: this.$t('common.renew'),cancelButtonText: this.$t('common.cancel'),
						center: true,
						showClose: false,
						customClass: 'deleteOk',
					}).then(()=>{
						this.$router.push({
							path:'/renew'
						})
					});
				}

				// 交易所
				this.$store.dispatch('markets/getList')

			})
		},
	})
</script>
<style scoped="scoped" lang="stylus">
	@import './home.styl';
</style>