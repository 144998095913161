var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"guidance",class:{
    guidance1:_vm.helpNum==1,
    left1:_vm.position=='left',
    center1:_vm.position=='center',
    right1:_vm.position=='right',
    guidance2:_vm.helpNum==2,
    guidance3:_vm.helpNum==3,
    guidance4:_vm.helpNum==4,
    right0:_vm.position2=='right0',
    right2:_vm.position2=='right',
    bottom2:_vm.position2=='bottom',
}},[_c('div',{staticClass:"header"},[_vm._v(_vm._s(_vm.helpNum?_vm.$t('stocks.welcomeText['+(_vm.helpNum-1)+'].title'):''))]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.helpNum?_vm.$t('stocks.welcomeText['+(_vm.helpNum-1)+'].text'):''))]),(_vm.helpNum==1)?_c('div',{staticClass:"btn_box"},[_c('div',{staticClass:"g_btn",on:{"click":_vm.next}},[_vm._v(_vm._s(_vm.$t('common.next')))])]):(_vm.helpNum==2||_vm.helpNum==3)?_c('div',{staticClass:"btn_box btn_box2"},[_c('div',{staticClass:"g_btn pre",on:{"click":_vm.pre}},[_vm._v(_vm._s(_vm.$t('common.pre')))]),_c('div',{staticClass:"g_btn",on:{"click":_vm.next}},[_vm._v(_vm._s(_vm.$t('common.next')))])]):(_vm.helpNum==4)?_c('div',{staticClass:"btn_box btn_box2"},[_c('div',{staticClass:"g_btn pre",on:{"click":_vm.pre}},[_vm._v(_vm._s(_vm.$t('common.pre')))]),_c('div',{staticClass:"g_btn",on:{"click":_vm.tipCheck}},[_vm._v(_vm._s(_vm.$t('stocks.know')))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }