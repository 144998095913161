import store from '@/store'

const { body } = document
const WIDTH = 993 // refer to Bootstrap's responsive design

export default {
  watch: {
    $route(route) {
      if (this.device === 'mobile' && this.isCollapse) {
        // this.isCollapse=true;
        store.commit('app/SET_IS_COLLAPSE', true);
        this.notTrans=false;
        // store.dispatch('app/closeSideBar', { withoutAnimation: false })
      }
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  mounted() {
    const isMobile = this.$_isMobile()
    if (isMobile) {
      store.dispatch('app/toggleDevice', 'mobile')
      // this.isCollapse=true;
      store.commit('app/SET_IS_COLLAPSE', true);
      this.notTrans=true;
      // store.dispatch('app/closeSideBar', { withoutAnimation: true })
    }
    const isSmall = this.$_isSmall()
    if(isSmall){
      store.dispatch('app/vwDevice', 'small')
    }
  },
  methods: {
    // use $_ for mixins properties
    $_isMobile() {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    },
    $_isSmall() {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < 750
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        store.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop')

        const isSmall = this.$_isSmall()
        store.dispatch('app/vwDevice', isSmall ? 'small' : 'normal')

        this.notTrans=true;
        if (isMobile) {
          // this.isCollapse=true;
          store.commit('app/SET_IS_COLLAPSE', true);
          // store.dispatch('app/closeSideBar', { withoutAnimation: true })
        }
      }
    }
  }
}
