<template>
    <div class="guidance" :class="{
        guidance1:helpNum==1,
        left1:position=='left',
        center1:position=='center',
        right1:position=='right',
        guidance2:helpNum==2,
        guidance3:helpNum==3,
        guidance4:helpNum==4,
        right0:position2=='right0',
        right2:position2=='right',
        bottom2:position2=='bottom',
    }">
        <div class="header">{{helpNum?$t('stocks.welcomeText['+(helpNum-1)+'].title'):''}}</div>
        <div class="content">{{helpNum?$t('stocks.welcomeText['+(helpNum-1)+'].text'):''}}</div>
        
        <div class="btn_box" v-if="helpNum==1">
            <div class="g_btn" @click="next">{{$t('common.next')}}</div>
        </div>
        <div class="btn_box btn_box2" v-else-if="helpNum==2||helpNum==3">
            <div class="g_btn pre" @click="pre">{{$t('common.pre')}}</div>
            <div class="g_btn" @click="next">{{$t('common.next')}}</div>
        </div>
        <div class="btn_box btn_box2" v-else-if="helpNum==4">
            <div class="g_btn pre" @click="pre">{{$t('common.pre')}}</div>
            <div class="g_btn" @click="tipCheck">{{$t('stocks.know')}}</div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    props:["position","position2"],
    computed:{
        ...mapGetters(['novice','helpNum','isCollapse','device']),
    },
    methods:{
        // 上一步
        pre(){
            if(this.helpNum==2){
                if(!this.isCollapse&&this.device=='mobile'){
                    this.$store.commit('app/SET_IS_COLLAPSE',true)
                }
            }
            if(this.helpNum==3||this.helpNum==4){
                if(this.isCollapse){
                    this.$store.commit('app/SET_IS_COLLAPSE',false)
                }
            }
            this.$store.commit('user/SET_HELP_NUM',this.helpNum-1)
        },
        // 下一步
        next(){
            if(this.helpNum==1||this.helpNum==2||this.helpNum==3){
                if(this.isCollapse){
                    this.$store.commit('app/SET_IS_COLLAPSE',false)
                }
            }
            this.$store.commit('user/SET_HELP_NUM',this.helpNum+1)
        },
        // 已知晓
        tipCheck(){
            this.$store.dispatch('user/setNovice', '0');
            this.$store.commit('user/SET_HELP_NUM',1)
        },
    },
}
</script>
<style lang="stylus" scoped>
@import '~@/assets/style/var.styl';
.guidance{
    position absolute
    top 40px
    width 255px
    background-color: var(--popBg);
    padding:  16px;
    radius: 2px
    //z-index 1000
    z-index 10001
    .header{
        color: var(--white)
    }
    .content{
        color: var(--textColor)
        margin 8px 0 16px
        font-size 12px
    }
    .btn_box{
        text-align: center
        color: var(--active)
    }
    .btn_box2{
        flexbox()
        justify-content: space-around
    }
    .pre{
        color: var(--textColor)
    }
}
.guidance1{
    &::before {
        content ''
        position absolute
        top -18px
        border: 10px solid transparent
        border-bottom-color: var(--popBg);
    }
    &.left1{
        left 0
        &::before {
            left 16px
        }
    }
    &.center1{
        left -90px
        &::before {
            left 122px
        }
    }
    &.right1{
        right 0.4rem
        &::before {
            right 16px
        }
    }
}
.guidance2,
.guidance3,
.guidance4{
    &::before {
        content ''
        position absolute
        border: 10px solid transparent
    }
    &.right0{
        left -10px
        &::before {
            left: -18px
            border-right-color: var(--popBg);
        }
    }
    &.right2{
        left 195px
        &::before {
            left: -18px
            border-right-color: var(--popBg);
        }
    }
    &.bottom2{
        &::before {
            top -18px
            border-bottom-color: var(--popBg);
        }
    }
    
}
.guidance2{
    &.right0,
    &.right2{
        top 2px
    }
    &.bottom2{
        top 68px
    }
}
.guidance3{
    &.right0,
    &.right2{
        top calc( 56px * 2 + 2px)
    }
    &.bottom2{
        top calc( 56px * 3 + 12px)
    }
}
.guidance4{
    &.right0,
    &.right2{
        top calc( 56px * 5 + 2px)
    }
    &.bottom2{
        top calc( 56px * 6 + 12px)
    }
}
</style>